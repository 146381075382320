<template>
  <v-app>
    <v-content>
      <v-layout row wrap fill-height align-center justify-center>
        <v-flex lg6 xs11>
          <div class="text-center" v-if="loading === true">
            <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
          </div>
          <div class="text-center" v-else-if="havefile === true">
            <v-card outlined style="background-color: #f2faff" class="elevation-2">
              <br />
              <v-card-text>
                <img src="@/assets/package.png" width="150" height="150">
              </v-card-text>
              <v-card-text style="font-size: 20px">
                <b>ชื่อไฟล์:</b>
                {{ filedata['filename'] }}
              </v-card-text>
              <v-card-text style="font-size: 20px">
                <b>ขนาดไฟล์:</b>
                {{ calculatesize(filedata['size_file'] )}}
              </v-card-text>
              <v-card-text>
                <v-btn color="primary" depressed :disabled="snackbardowload" @click="downloadfile(filedata)">ดาวน์โหลด</v-btn>
                <v-btn  class="ma-2" color="primary" depressed outlined :disabled="snackbardowload" @click="check_file()" >ดูไฟล์</v-btn>
              </v-card-text>
              
              <br />
              <v-divider></v-divider>
              <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action>
            </v-card>
          </div>
          <div class="text-center" v-else>
            <v-card outlined class="elevation-2" style="background-color: #f2faff">
              <br />
              <v-card-text>
                <v-icon size="100" medium color="red">mdi-file-cancel</v-icon>
              </v-card-text>
              <v-card-text style="font-size: 20px">ไม่พบไฟล์นี้ในระบบ หรือ ไฟล์นี้ถูกปิดการแชร์</v-card-text>
              <br />
              <v-divider></v-divider>
              <v-card-action>
                <p class="pt-1" >
                  Powered By &nbsp;
                  <img
                    @click.stop="gotohomepage()"
                    class="pt-2"
                    style="vertical-align: text-bottom;"
                    src="@/assets/logo_onebox-horizontal-png.png"
                    width="65px"
                    height="35px"
                    alt="One Box2"
                  />
                </p>
              </v-card-action>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
       <!-- snackbardowload -->
    <!-- <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar color="primary" dense short flat fab>
            {{$t('myinboxPage.dowload.dowloading')}}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowload = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{namedowload}}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
            >&nbsp; {{ percentCompleted }}</v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div> -->
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload=false"
      :percent="percent"
      :namedowload="namedowload"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
    <previewpublicfile
      :show="openpreviewpublicfile"
      :filedata="currentfile"
      :parentfolder="parentfolder"
       @closepreviewfile="openpreviewpublicfile = false"
       @openpreviewfile="openpreviewpublicfile = true"
    ></previewpublicfile>
     <dialogsnackbardowloadfile
        :show="opensnackbardownloadfile"
        @closedialog="opensnackbardownloadfile = false"
        :filedata="file"
        :percen="newpercen"
        :name="namefile"
        :list="new_list"
        :btsdownload="btsdownload"
      ></dialogsnackbardowloadfile>
    </v-content>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { saveAs } from "file-saver";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const previewpublicfile = () => import("../components/optional/dialog-previewpublic");
const dialogsnackbardowloadfile = () => import("../components/optional/dialog-snackbardownloadfile");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  data: function() {
    return {
      opensnackbardownloadfile:false,
      btsdownload:false,
      checkfilename:'',
      typefile:[],
      name: "",
      parentfolder: "",
      openpreviewpublicfile:false,
      opensnackbarmultidownload:false,
      percent:'',
      namedowload:'',
      timeout: 60000,
      percentCompleted: 0,
      snackbardowload:false,
      loading: true,
      havefile: false,
      filedata: {},
      // currentfile:{},
      currentfile: {
        file_icon: ["", ""],
        file_id: "",
        file_name: "",
        file_size: "",
        file_status: "",
        file_type: "",
      },
    };
  },
  components:{dialogsnackbermultidowload,previewpublicfile,dialogsnackbardowloadfile},
  methods: {
    check_file(){
      console.log("เข้าcheck_fileจ้า");
      // this.currentfile = file;
      this.currentfile = this.filedata;
      this.openpreviewpublicfile = true;
      // console.log("this.currentfile ***",this.currentfile);
      // console.log("this.openpreviewpublicfile",this.openpreviewpublicfile);
    },
    async getfiledetail() {
      let payload = {
        link: this.$route.query.id
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/get_share_file_link",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false
        },
      );

      try {
        console.log(response.data);
        if (response.data.status === "OK") {
          this.loading = false;
          this.havefile = true;
          this.filedata = response.data.result[0];
          this.checkfilename = this.filedata.filename.substring(this.filedata.filename.length-3, this.filedata.filename.length)
          console.log("นี่",this.checkfilename)         
        } else {
          this.loading = false;
          this.havefile = false;
        }
      } catch (ex) {
        this.loading = false;
        this.havefile = false;
      }
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
       return parseFloat((_sizefilebyte / 1073741824).toFixed(2))
      // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    async downloadfile(currentfile) {
      // console.log(currentfile);   
      let checkfilesize = this.fn_calculate_size(currentfile.size_file)
      if (currentfile.file_type === "folder") {
        Toast.fire({
          type: "warning",
          title: "Coming Soon !!"
        });
     } else {
        // let payload = {
        //   account_id: this.dataAccountId,
        //    user_id: currentfile.user_id,
        //   file_id: currentfile.id
        // };
          let url = 
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + 
          "/api/v2/download_file?user_id="+ 
          currentfile.user_id + 
          "&file_id=" + 
          currentfile.id;
        if(checkfilesize < 1.00 ){

        
        this.snackbardowload = true;
        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.filename;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .get(url, {
            headers: { Authorization: auth.code },
            onDownloadProgress: progressEvent => {
              let progresspercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // console.log(this.percentCompleted);
              // this.namedowload = currentfile.filename;
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              }else{
                this.percentCompleted = progresspercent
              }
              this.percent = this.percentCompleted
            },
            withCredentials: false,
            responseType: "arraybuffer"
          })
          .then(response => {
            if (response.statusText === "OK") {
              if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
              this.opensnackbarmultidownload = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("res_error downloadall",res_error);
              Toast.fire({ icon: "error", title: res_error.errorMessage });
              } else {

                this.percentCompleted = 100;
                this.btsdownload = false;
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, currentfile.filename);
                let headers = response.headers;
                let blob = new Blob([response.data],{type:headers['content-type']});
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = currentfile.filename;
                link.click();
                setTimeout(() => {
                  this.opensnackbarmultidownload = false
                  }, 2500);
            }
            }
            // console.log("fff",response);
            this.snackbardowload = false;
          })
          .catch(error => {
            this.btsdownload = false;
            this.snackbardowload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้"
            });
            console.log(error);
          });
        }else{
          this.opensnackbardownloadfile = true;
           this.btsdownload = true;
          window.location.assign(url)
          let  timeout = (checkfilesize*4000)
          setTimeout(() => {
            this.opensnackbardownloadfile = false;
          }, timeout)
        }
      }
    },
    // downloadfile(currentfile) {   
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       icon: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let payload = {
    //       user_id: currentfile.user_id,
    //       file_id: currentfile.id
    //     };
    //     let url = process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/v2/download_file?user_id="+ currentfile.user_id + "&file_id=" + currentfile.id;
        
    //     const link = document.createElement("a")
    //     link.setAttribute("href", url); //or any other extension
    //     link.setAttribute("download", currentfile.file_name); //or any other extension
    //     document.body.appendChild(link);
    //     // document.body.removeChild(link); 
    //     setTimeout(() => { 
    //       link.click();  
    //       // Cleanup the DOM 
    //       document.body.removeChild(link); 
    //     }, 500); 
    //     // this.axios
    //     //   .post(
    //     //     process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_file",
    //     //     payload,
    //     //     {
    //     //       withCredentials: false,
    //     //       responseType: "blob"
    //     //     }
    //     //   )
    //     //   .then(response => {
    //     //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     //     const link = document.createElement("a");
    //     //     link.href = url;
    //     //     link.setAttribute("download", this.filedata["filename"]); //or any other extension
    //     //     document.body.appendChild(link);
    //     //     link.click();
    //     //   })
    //     //   .catch(error => {
    //     //     Toast.fire({
    //     //       icon: "error",
    //     //       title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
    //     //     });
    //     //     console.log(error);
    //     //   });
    //   }
    // },
    calculatesize(parameter) {
      let size;
      if (parameter >= 10000000000000 && parameter <= 1000000000000000) {
        size = (parameter / 10000000000000).toFixed(2) + " TB";
      } else if (parameter >= 1000000000 && parameter <= 1000000000000) {
        size = (parameter / 1000000000).toFixed(2) + " GB";
      } else if (parameter >= 1048576 && parameter <= 1000000000) {
        size = (parameter / 1000000).toFixed(2) + " MB";
      } else if (parameter >= 1000 && parameter <= 1000000) {
        size = (parameter / 1000).toFixed(2) + " KB";
      } else if (parameter === "-") {
        size = parameter;
      } else {
        size = parameter + " B";
      }

      return size;
    },
    gotohomepage(){
        this.$router.push('/')
    }
  },
  mounted() {
    if (this.$route.query.id === "" || !this.$route.query.id) {
      this.loading = false;
    } else {
      this.getfiledetail();
      
    }
    sessionStorage.removeItem("onechat_token");
    sessionStorage.removeItem("onechat_plugin_enable");
    //console.log("onechat_token",sessionStorage.getItem("onechat_token"));
  }
};
</script>
<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
</style>